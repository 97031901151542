<template>
  <b-container
    class="pt-5 bg-blue text-white fontStandard bottom_padding fontStandard"
    fluid
  >
    <h3 class="text-center">
      <i class="big_text fas fa-user-edit pb-1" />
      <br />

      <b-badge pill variant="light">
        <strong class="fontStandard text-primary">
          <BTextUppercase>Changer le mot de passe</BTextUppercase>
        </strong>
      </b-badge>
    </h3>
    <br />

    <!-- <b-card class="bg-blue bottom_padding fontStandard">
    <b-card-body class="mx-auto text-white">
      <b-card-title class="text-center">
        <i class="big_text fas fa-user-edit" />
      </b-card-title>
    <b-card-title title-tag="h3" class="mt-3 text-center">Changer le mot de passe</b-card-title>-->

    <b-form class="mx-auto max_width_400">
      <!-- Formulaire -->
      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-lock"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            class="form-control"
            placeholder="Votre mot de passe actuel"
            type="password"
            v-model="current_passwd"
            autocomplete="new-password"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-lock"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="content_password1"
            class="form-control"
            placeholder="Nouveau mot de passe"
            :type="get_type_password"
            v-model="passwd1"
            autocomplete="new-password"
            :state="check_password(passwd1, passwd2)"
          />
          <b-input-group-prepend>
            <b-button @click="generatePassword()" variant="primary">
              <i class="fas fa-key" />
            </b-button>
          </b-input-group-prepend>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-lock"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            id="content_password2"
            class="form-control"
            placeholder="Confirmer ce mot de passe"
            :type="get_type_password"
            v-model="passwd2"
            :state="check_password(passwd1, passwd2)"
          />

          <b-input-group-prepend>
            <b-button
              @click="changeTypePassword = !changeTypePassword"
              variant="primary"
            >
              <i
                :class="['fas', changeTypePassword ? 'fa-eye-slash' : 'fa-eye']"
              />
            </b-button>
          </b-input-group-prepend>
          <b-form-invalid-feedback :state="check_password(passwd1, passwd2)"
            >8 caractères min, 1 majuscule, 2 minuscules, 2
            chiffres</b-form-invalid-feedback
          >
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="account.axios_data && account.axios_data.picture">
        <b-input-group>
          <b-img
            width="500"
            fluid
            thumbnail
            center
            rounded="top"
            block
            :src="account.axios_data.picture"
            alt="captcha"
          />
          <b-input-group-prepend>
            <b-button @click="renewCaptcha()" variant="primary">
              <i class="fas fa-undo-alt" />
            </b-button>
          </b-input-group-prepend>
          <b-input
            class="form-control"
            placeholder="Captcha"
            type="text"
            v-model="captcha"
            @click="captcha = ''"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-button type="submit" variant="primary" @click="change_account" block>
          <i class="fas fa-user-edit" /> Modifier
        </b-button>
      </b-form-group>

      <!-- Formulaire -->
    </b-form>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/mixins/Form";

export default {
  components: {
    BTextUppercase: () => import("@/components/BTextUppercase"),
  },
  mixins: [Form],
  data() {
    return {
      current_passwd: "",
    };
  },
  mounted() {
    this.loadContent();
  },
  beforeDestroy() {
    this.content = this.current_passwd = this.passwd1 = this.passwd2 = this.extra_user_info = this.captcha = undefined;
  },
  computed: {
    ...mapState(["account", "events"]),
  },
  methods: {
    async change_account(e) {
      e.preventDefault();

      const Obj = {
        username: this.content.username,
        current_password: this.current_passwd,
        set_password1: this.passwd1,
        set_password2: this.passwd2,
        captcha: this.captcha,
      };

      if (!this.dataChanged()) {
        return this.$root.showToast(
          `Modification`,
          `Aucun changement`,
          "warning",
          2000
        );
        await this.loadContent();
      }

      await this.$root.axios("put", "api/auth/change", Obj);

      if (
        this.account.axios_data &&
        this.account.axios_data.auth_response === "change_login_info" &&
        this.account.axios_data.check_input.length === 0
      ) {
        this.$root.showToast(
          `Modification`,
          `Mot de passe changé avec succès`,
          "info",
          3500
        );
        this.passwd1 = this.passwd2 = "";
        await this.loadContent();
      }
    },
  },
};
</script>
